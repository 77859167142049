import React from 'react';
import { useState, useEffect } from 'react';
import Modal from'react-modal'
import { withRouter } from "react-router-dom";
import Window from './window'
import '../css/modal.css'

const style = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    content: {
        position: 'absolute',
        top: '40px',
        left: 20,
        right: 20,
        bottom: '40px',
        border: '0px solid #ccc',
        overflow: 'normal',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: 0,
        backgroundColor: 'rgba(255, 255, 255, 0)'
    }
}

Modal.setAppElement('#app')

const MyModal = ({location, history}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const myRef = React.createRef();

    useEffect(() => {
        setIsOpen(location.pathname.split("/")[1]);
    }, [location.pathname])
    useEffect(()=>{
        // setClosing(false);
    }, [closing])

    const redirect=()=>{
        setClosing(true);
        history.push("/")
    }
    return (<div>
        <Modal shouldCloseOnOverlayClick style={style} isOpen={isOpen} closeTimeoutMS={200} 
            onRequestClose={redirect} className={"animate__animated animate__fadeInUp "+(closing?" animate__bounceOut":"")}
            ref={myRef}
        >
            <Window redirect={redirect} smallScreen={true} />
        </Modal>
    </div>)
}

export default withRouter(props => <MyModal {...props} />);
