import React, { useEffect } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import education from '../../dataModel/education'
import '../../css/education.css'
const EduElem = ({ exp }) => {
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={exp.year}
            iconStyle={{ background: '#fff', color: '#000' }}
            icon={exp.icon || <div />}
            position="left"
        >
            <div className="inner" style={{ textAlign: "left" }}>
                <div>
                    <h3 className="vertical-timeline-element-title">{exp.name}</h3>
                    <h4 className="vertical-timeline-element-subtitle">{exp.school}</h4>
                    <p style={{ margin: 0 }}>{exp.stream}</p>
                    <p style={{ margin: 0 }}>{exp.score}</p>
                </div>
                <div className="img">
                    <img alt={exp.name} src={exp.img} ></img>
                </div>
            </div>

        </VerticalTimelineElement>
    )
}

const Education = () => {
    useEffect(() => {
        document.title = `Education | Shubham Awasthi`
    }, [])

    return <div className="animateWrapper" >
        <VerticalTimeline layout="1-column" style={{ margin: 1000 }}>
            {
                (() => {
                    var arr = []
                    education.forEach(exp => {
                        arr.push(<EduElem key={exp.name} exp={exp} />);
                    })
                    return arr;
                })()
            }
        </VerticalTimeline>
    </div>
}

export default Education