import React, { useEffect } from 'react'
import { Box, Button } from 'react-desktop/macOs'
import '../../css/projects.css'
import projects from '../../dataModel/projects'
import { getColorBorder } from "../../utility";

const Project = ({ proj }) => {

    return (
        <Box className="project animateWrapper">
            <div className="content">
                <h2>{proj.projectName}</h2>
                <p>{proj.endDate ? `${proj.startDate} - ${proj.endDate}` : proj.startDate}</p>
                <div>
                    <ul>
                        {(() => proj.info.map((inf, ind) => {
                            return <li key={ind}>{inf}</li>
                        }))()
                        }
                    </ul>
                </div>
            </div>
            <div className="badges">
                {(() => {
                    if (proj.tech) return proj.tech.map((t, ind) => {
                        return <span className="animateImg" key={ind} style={{ ...getColorBorder(t) }}>{t}</span>
                    })
                })()
                }
            </div>
            <div>
                {
                    (() => {
                        if (!proj.links) return <div />
                        return proj.links.map((link, ind) => {
                            return (
                                <a key={ind} className="no-decoration" href={link.link} target="_blank" rel="noreferrer"  >
                                    <Button color="blue" style={{ margin: 10 }}>
                                        {link.title}
                                    </Button>
                                </a>
                            )
                        })


                    })()
                }
            </div>
        </Box>
    )
}

const Projects = () => {
    useEffect(() => {
        document.title = `Projects | Shubham Awasthi`
    }, [])
    return (
        <div className="projects-wrapper">
            {
                (() => {
                    var arr = []
                    projects.forEach((proj, ind) => {
                        arr.push(<Project key={ind} proj={proj} />);
                    })
                    return arr;
                })()
            }
        </div>
    )
}

export default Projects