import PhotoGrid from './photo-grid'
import photoGridData from '../../dataModel/photoGrid'
import { useEffect } from 'react'

const Display = ()=> {
    useEffect(()=>{
        document.title = `Achievements | Shubham Awasthi`
    },[])
    return <PhotoGrid images={photoGridData.achievements} className="achivement-wrapper" />
}

export default Display