import PhotoGrid from './photo-grid'
import photoGridData from '../../dataModel/photoGrid'
import React, { useEffect } from 'react'

const EC = () => {
    useEffect(() => {
        document.title = `Extracurricular | Shubham Awasthi`
    }, [])
    return <PhotoGrid images={photoGridData.extracurricular} className="extracurricular-wrapper" />
}

export default EC