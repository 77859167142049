import React from 'react'
import { ImSad } from 'react-icons/im'
import '../css/404.css'

const E404 = () => {
    return (
        <div className="w404">
            <div className="wrapper404">
                <div className="tile404">
                    <div className="icon"><ImSad /></div>
                    <div className="title">404</div>
                    <div className="sub-title">Page Not Found</div>
                    <div className="content">The page you are looking for does not exists. </div>
                    <a className="link" href="/">shubham.awasthi.dev </a>
                </div>
            </div>
        </div>
    )
}

export default E404;