const c2c = './static/resources/extracurricular/c2c.jpg'
const codart = './static/resources/extracurricular/codart.jpg'
const cyd = './static/resources/extracurricular/cyd.jpg'
const lbh = './static/resources/extracurricular/lbh.jpeg'
const pyflask = './static/resources/extracurricular/pyflask.jpg'
const rc = './static/resources/extracurricular/rc.jpeg'
const weekenlabs = './static/resources/extracurricular/weekendlabs.jpeg'

const vitHack = './static/resources/achievements/vit_hack.jpg'
const bcm = './static/resources/achievements/shubham-bcm.png'
const zeit = './static/resources/achievements/zeit.jpg'
const infytq = './static/resources/achievements/infytq.jpg'

const photoGrid = {
    achievements: [
        { img: vitHack, title: "VIT Hack Winner", content: "Won First Place and a cash prize of ₹1Lakh at VIT Hackathon 2019 under Mobility Track" },
        { 
            img: zeit, 
            title: "Zeit Challenge", content: "Won a prize of $300 at Zeit challenge presented by Vercel"
        },
        {
            img: bcm,
            title: "BeChangeMaker 2018", content: "Selected as the top 9th team of BeChangeMaker 2018"
        },
        {
            img: infytq,
            title: "Infosys Certification", content: "Certification exam organised by Infosys to be the Infosys Certified Developer."
        }

    ],
    extracurricular: [
        {   img: cyd, title: "Code Your Dreams", 
            content: "A session was organised in an local government school where we taught the children about the basics of computers and motivated them towards the concept of coding. " },
        {   img: weekenlabs, title: "Weeken Labs", 
            content: "An initiative to teach topics uncovered academically to fellow students on weekends" },
        {   img: lbh, title: "Local Hack Day Build", 
            content: "A one day hack to give little teaser to freshers into the world of competitive creation." },
        {   img: c2c, title: "Code 2 Create", 
            content: "Our team organized the hackathon: Code2Create one of the flagship event of VIT Vellore" },
        {   img: rc, title: "Reverse Coding", 
            content: "A coding event wherein executable files were provided and the participants were expected to develop code for the same. I contributed in making an online portal for problem submission." },
        {   img: pyflask, title: "PyFlask", 
            content: "A workshop organized by us to spread the knowledge and utility of Pyflask amongst freshers." },
        {   img: codart, title: "Codart", 
            content: "A coding event wherein the questions were given based on the proximity of their thrown dart to the center. An electronic dart board was made from scratch by me and my team members at ACM." },
    ]
}

export default photoGrid