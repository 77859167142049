import React, { useEffect } from 'react'
import certificates from '../../dataModel/certificates'
import { Button } from 'react-desktop/macOs';
import PhotoGrid from './photo-grid'

const Certificates = () => {
  useEffect(() => {
    document.title = `Certificates | Shubham Awasthi`
  }, [])
  const elements = certificates.map(({ img, platform, link, title }) => {
    return {
      img: img, title: title,
      content: (
        <div ><p><b>{platform}</b></p>
          <div>
            <a className="no-decoration" href={link} target="_blank" rel="noreferrer"  >
              <Button color="blue"> Open Certificate</Button>
            </a>
          </div></div>
      )
    }

  })
  return (
    <PhotoGrid images={elements} className="cert-wrapper" />
  )
}

export default Certificates //About