import React, { useEffect } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import experiences from '../../dataModel/experience'
import { Button } from 'react-desktop/macOs';

const ExpElemet = ({ exp }) => {
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={`${exp.startDate} - ${exp.endDate}`}
            iconStyle={{ background: '#fff', color: '#fff', overflow: "hidden" }}
            icon={exp.icon || <div />}
        >
            <h3 className="vertical-timeline-element-title">{exp.companyName}</h3>
            <h4 className="vertical-timeline-element-subtitle">{exp.role}</h4>
            <div className="timelineElem">
                <ul>
                    {(() => exp.info.map((inf) => {
                        return <li key={exp.companyName + inf} >{inf}</li>
                    }))()
                    }
                </ul>
            </div>
            {exp.links && <hr style={{ margin: "10px 0" }} />}
            {
                (() => {
                    if (!exp.links) return <div />
                    return exp.links.map((link) => {
                        return (
                            <a key={exp.companyName + link.link} className="no-decoration" href={link.link} target="_blank" rel="noreferrer"  >
                                <Button color="blue" style={{ margin: 10 }}>
                                    {link.title}
                                </Button>
                            </a>
                        )
                    })


                })()
            }


        </VerticalTimelineElement>
    )
}

const Experience = () => {
    useEffect(() => {
        document.title = `Experience | Shubham Awasthi`
    }, [])
    return <div className="animateWrapper" >
        <VerticalTimeline>
            {
                (() => {
                    var arr = []
                    experiences.forEach(exp => {
                        arr.push(<ExpElemet key={exp.companyName + exp.role + exp.startDate} exp={exp} />);
                    })
                    return arr;
                })()
            }
        </VerticalTimeline>
    </div>
}

export default Experience