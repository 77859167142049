const ccImg = './static/resources/codingProfile/cc_logo.png'
const cfImg = './static/resources/codingProfile/cf_logo.png'
const lcImg = './static/resources/codingProfile/lc_logo.png'
const hrImg = './static/resources/codingProfile/hr_logo.png'

const CP = {
    platforms: [
        {
            name: "Codechef",
            image: ccImg,
            rating: "2012",
            title: "5 Star",
            username: "awasthishubh",
            link: "https://www.codechef.com/users/awasthishubh"
        },
        {
            name: "Codeforces",
            image: cfImg,
            rating: "1737",
            title: "Expert",
            username: "awasthishubh",
            link: "https://codeforces.com/profile/awasthishubh"
        },
        {
            name: "Leetcode",
            image: lcImg,
            rating: "1664",
            title: null,
            username: "awasthishubh",
            link: "https://leetcode.com/awasthishubh/"
        },
        {
            name: "Hackerrank",
            image: hrImg,
            rating: null,
            title: "Problem Solving: 6 Stars",
            username: "awasthi_shubh",
            link: "https://www.hackerrank.com/awasthi_shubh"
        },
    ],
    topPerforms: [
        "Ranked 309/10000 (India Rank: 98) at Google Kickstart 2020 round B",
        "Ranked 269/1379 (College Rank: 1) at Codechef June Challenge 2020 Division 1",
        "Ranked 156/19623 (College Rank: 1) at Codechef May Challenge 2020 Division 2",
        "Ranked 44/17663 at Codeforces Round #674 (Div. 3)",
        "Ranked 191/6572 at Leetcode: Biweekly Contest 41",
    ],
    topPerformsStats: [
        {
            sno: 1,
            platform: "Google Kickstart",
            contest: "2020 round B",
            rank: 309,
            total: 10000
        },
        {
            sno: 2,
            platform: "Codechef",
            contest: "June Challenge 2020 Division 1",
            rank: 269,
            total: 1379
        },
        {
            sno: 3,
            platform: "Codechef",
            contest: "May Challenge 2020 Division 2",
            rank: 156,
            total: 19623
        },
        {
            sno: 4,
            platform: "Codeforces",
            contest: "Round #674 (Div. 3)",
            rank: 44,
            total: 17663
        },
        {
            sno: 5,
            platform: "Leetcode",
            contest: "Biweekly Contest 41",
            rank: 191,
            total: 6572
        },
    ],
    numberOfProblems: [
            { platform: "Codeforces + Codechef + Hackerrank", count: 450, source: { title: "StopStalk", link: "https://www.stopstalk.com/user/profile/awasthishubh" } },
            { platform: "Leetcode", count: 320, source: { title: "Leetcode", link: "https://leetcode.com/awasthishubh/" } }
        ]
}


export default CP