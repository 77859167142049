const links={
    facebook: "https://www.facebook.com/awasthi.shubh/",
    linkedin: "https://www.linkedin.com/in/awasthishubh/",
    twitter: "https://twitter.com/aw_shubh",
    mail: "aw.shubh@gmail.com",
    mailLink: "mailto:aw.shubh@gmail.com"
}
const emailjsConfig = {
    service: "default_service",
    template: "template_2R074DMp",
    userID: "user_9mxHU5FskG67KLw6nkdQL"
}
const reCaptcha = {
    siteKey: '6LfNxxQaAAAAAOpi0_Duf9361Oen2HA9XCDjhXwx'
}
export {
    links, emailjsConfig, reCaptcha
}