import React, { useEffect, useState } from 'react'
import { Box, Button } from 'react-desktop/macOs'
import '../../css/contact.css'
import emailjs from 'emailjs-com';
import { FaLinkedinIn, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { links, emailjsConfig } from '../../dataModel/miscellaneous'
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptcha } from '../../dataModel/miscellaneous'

const Contact = () => {
    const [messageSent, setSentStatus] = useState(0);
    const [captcha, setCaptcha] = useState(null);
    useEffect(() => {
        document.title = `Contact | Shubham Awasthi`
    }, [])
    const send = (e) => {
        e.preventDefault();
        if (!captcha) return setSentStatus(3);
        setSentStatus(2);

        emailjs.sendForm(emailjsConfig.service, emailjsConfig.template, e.target, emailjsConfig.userID)
            .then((result) => {
                console.log(result.text);
                setSentStatus(1);
                e.target.reset();
            }, (error) => {
                console.log(error.text);
                setSentStatus(-1);
            });

    }
    const setMessage = () => {
        if (messageSent === 1)
            return <div className={"message ok"}>Message Sent!</div>;
        if (messageSent === -1)
            return <div className={"message err"}>
                <span>Unable to send message.<br />
                        Please use below platforms to connect.
                    </span>
            </div>;
        if (messageSent === 3)
            return <div className={"message err"}>Please verify reCaptcha.</div>;
    }
    return (
        <div className="contact-wrapper animateWrapper">
            <Box className="contact-box">
                <form onSubmit={send} className="field-wrapper">
                    <div className="left">Name</div>
                    <div className="right">
                        <input
                            name="name"
                            required placeholder="Your name"
                        />
                    </div>
                    <div className="left">Email</div>
                    <div className="right">
                        <input
                            name="email"
                            type="email" required
                            placeholder="Your email address"
                        />
                    </div>
                    <div className="left">Message</div>
                    <div className="right">
                        <textarea
                            className="setFont"
                            required
                            name="message"
                            placeholder="Enter your message here"
                            style={{ resize: "none" }}
                        />
                    </div>
                    <div id="reCaptcha" style={{ height: 78 }}>
                        <ReCAPTCHA
                            sitekey={reCaptcha.siteKey}
                            onChange={(e) => { setCaptcha(e); messageSent === 3 && setSentStatus(0) }}
                        />
                    </div>
                    <div className="button">
                        <Button type="submit" color="blue" className={messageSent === 2 ? "disable" : ""}>
                            {messageSent === 2 ? "Sending..." : "Send"}
                        </Button>
                    </div>
                    {setMessage()}
                </form>
                <div className="social">
                    <a className="no-decoration icon" href={links.linkedin} aria-label="LinkedIn" target="_blank" rel="noreferrer" >
                        <FaLinkedinIn />
                    </a>
                    <a className="no-decoration icon" href={links.facebook} aria-label="Facebook" target="_blank" rel="noreferrer" >
                        <FaFacebookF />
                    </a>
                    <a className="no-decoration icon" href={links.twitter} aria-label="Twitter" target="_blank" rel="noreferrer" >
                        <FaTwitter />
                    </a>
                </div>
                <div>
                    or mail me at: <a className="link" href={links.mailLink}>{links.mail}</a>
                </div>
            </Box>
        </div>
    )
}

export default Contact