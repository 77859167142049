// eslint-disable-next-line
import layout from './dataModel/layout'

const colorsBadgesDark = ["#9e9d24", "#01579b", "#0277bd", "#00695c", "#00acc1", "#5e35b1", "#1976d2", "#0d47a1", "#e53935", "#d81b60", "#8e24aa", "#4a148c", "#6d4c41",]
const colorsBadgesLight = ["#c6ff00", "#76ff03", "#40c4ff", "#1de9b6", "#18ffff", "#ff1744", "#d500f9", "#e040fb", "#64ffda", "#ff6e40", "#ff3d00", "#ffff00", "#ffea00",]
const delays = ["0ms", "100ms", "200ms", "300ms", "400ms"]


const generateHash = (s) => {
    var hash = 0, i, chr;
    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
}
const getColorBorder = (s) => {
    const X = (generateHash(s) % 30 + 30) % 30;
    return { color: "#000", border: `2.5px solid ${colorsBadgesDark[X % colorsBadgesDark.length]}`, background: 'white' } //colorsBadgesLight[X % colorsBadgesLight.length] }
}

const getRColor = () => {
    return colorsBadgesLight[Math.round(Math.random() * colorsBadgesLight.length)]
}

const getDelay = () => {
    return delays[Math.round(Math.random() * delays.length)];
}

const isSmall = window.innerWidth <= 850 || window.innerHeight <= 400;

const addBold = (s) => {
    return <span> {s.split('*').map((e, i) => {
        if (i % 2 === 0) return <span key={i}>{e}</span>
        else return <span key={i}><b>{e}</b></span>
    })} </span>;
}

const getLink = (a) => `/${a}`

export {
    isSmall, getColorBorder, getDelay, getRColor, addBold, getLink
}