import React, { useEffect } from 'react'
import { Box } from 'react-desktop/macOs';
import skillData from "../../dataModel/skills";
import { getColorBorder } from "../../utility";
import '../../css/skills.css'
const Skill = ({ skill }) => {
  const [skillName, Icon] = skill;
  return (
    <div className="skill animateImg" style={{ ...getColorBorder(skillName) }}>
      <div className="icon">{Icon ? <Icon /> : <div />}
      </div>
      <div className="name">{skillName}</div>
    </div>
  )
}
const Skills = ({ name, skills }) => {
  return (
    <Box className="type-wrapper">
      <div className="head"><h2>{name}</h2></div>
      <div className="cnt-wrp">
        <div className="content">
          {
            (() => {
              return skills.map((skill, ind) => <Skill key={ind} skill={skill} />)
            })()
          }
        </div>
      </div>
    </Box>
  )
}
const SK = () => {
  useEffect(() => {
    document.title = `Skills | Shubham Awasthi`
  }, [])
  return (
    <div className="skills-wrapper animateWrapper">
      {
        (() => {
          var arr = []
          for (var skillName in skillData) {
            arr.push(<Skills key={skillName} name={skillName} skills={skillData[skillName]} />)

          }
          return arr;
        })()
      }
    </div>
  )
}

export default SK