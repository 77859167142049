import React, { useEffect } from 'react'
import { Box } from 'react-desktop/macOs'
import data from '../../dataModel/me'
import '../../css/me.css'
import { withRouter } from "react-router-dom";

const El = ({ className, Icon, title, subTitle, para, link, history, img, id }) => {
    const onClick = () => {
        if (link) history.push(link)
    }
    return <Box style={{ gridArea: id }} className={className} onClick={onClick}>

        <div>
            <div>
                <span className="icon"><Icon /></span>
                <span className="box-title"><b>{title}</b></span>
            </div>
            {(() => {
                return para.map((p, id) => <p key={id}>{p}</p>)
            })()}
        </div>


    </Box>
}
const Me = ({ history }) => {
    useEffect(() => {
        document.title = `Me | Shubham Awasthi`
    }, [])
    return (
        <Box className="about-me-wrapper animateWrapper">
            <div style={{ gridArea: data.head.id }} className={data.head.className}>
                <div>
                    <div>
                        <h2>{data.head.title}</h2>
                        {data.head.subTitle && <h3>{data.head.subTitle}</h3>}
                    </div>
                    {(() => {
                        return data.head.para.map((p, id) => <p key={id}>{p}</p>)
                    })()}
                </div>


            </div>
            {(() => {
                return data.sections.map((el) => {
                    return <El {...el} key={el.title} history={history} />
                })
            })()}
            <div className="img hide-on-vsmall enterRight" style={{ gridArea: data.img.id }}>
                <img src={data.img.src} alt={"Shubham Awasthi"} />
            </div>
            <Box className="img show-on-vsmall enterRight" style={{ gridArea: data.img.id }}>
                <img src={data.img.src} alt={"Shubham Awasthi"} />
            </Box>
        </Box>
    )
}

export default withRouter(props => <Me {...props} />);