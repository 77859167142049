import React from 'react'
import { Box } from 'react-desktop/macOs';
import '../../css/photo-grid.css'
import ImgsViewer from 'react-images-viewer'

const El = ({ img, title, content, ind, setOpen, setImg }) => {
    const onClick = () => {
        setImg(ind);
        setOpen(true);
    }
    return (
        <Box className="box animateWrapper" onClick={onClick}>
            <div className="img animateImg">
                <img src={img} width="100%" alt={title} />
            </div>
            <div className="title">
                <h2>{title}</h2>
            </div>
            <div className="content">
                <div>
                    {content}
                </div>
            </div>

        </Box>
    )
}

const PhotoGrid = ({ images, className }) => {
    const [isOpen, setOpen] = React.useState(false);
    const [currImg, setImg] = React.useState(0);
    const imgSet = images.map(({ img, title, content }) => {
        return { src: img, caption: `${title} ${typeof (content) === "string" ? ": " + content : ""}`, }
    })
    return (
        <div className={className + " photo-grid-wrapper"} >
            <ImgsViewer
                imgs={imgSet}
                currImg={currImg}
                isOpen={isOpen}
                onClickPrev={() => setImg((currImg - 1) % imgSet.length)}
                onClickNext={() => setImg((currImg + 1) % imgSet.length)}
                onClose={setOpen.bind(null, false)}
            />
            {(() => {
                return images.map((el, ind) => <El key={el.title} {...el} setOpen={setOpen} setImg={setImg} ind={ind} />)
            })()}
        </div>
    )
}
export default PhotoGrid