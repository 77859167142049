import React, { useEffect, useState, createRef } from 'react';
import { Window, TitleBar } from 'react-desktop/macOs';
import { withRouter, Route } from "react-router-dom";
import layout from '../dataModel/layout'
import {getLink} from '../utility'
import '../css/window.css'

const App = ({ redirect, smallScreen, location }) => {
    const [title, setTitle] = useState("About");
    const cntRef = createRef();
    useEffect(() => {
        if (location.pathname === "/") {
            document.title = `Shubham Awasthi | Software Engeener`
            return;
        }
        layout.smallTiles.every((view) => {
            if (getLink(view.link) === location.pathname) {
                setTitle(view.title);
                document.title = `${view.title} | Shubham Awasthi`
                cntRef.current.scrollTop = 0;
                return false;
            }
            return true;
        })
    }, [cntRef, location.pathname])
    var styles = {
        chrome: true,
        height: "100%",
        padding: "10px",
        width: "100%",
        style: {
            borderRadius: "0.5rem",
        }
    }
    if (!smallScreen) styles = {
        height: "100%",
        padding: "50px",
        width: "100%",
        style: {
            borderRadius: "0.5rem",
        }
    }
    return (
        <Window {...styles} className="shadow window-wrapper">
            <TitleBar onClick={redirect} title={title} controls={smallScreen} inset onCloseClick={redirect} />
            <div className="cnt" style={{ color: "black" }} ref={cntRef}>
                {(() => layout.smallTiles.map((view) =>
                    <Route key={view.link} path={getLink(view.link)} component={view.view} />
                )
                )()}
            </div>
        </Window>
    );
}

export default withRouter(props => <App {...props} />);